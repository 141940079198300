import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = (props) => {
    const [testoData, setTestData] = useState([]);
    const [aboutData, setAboutData] = useState([]);
    const [pageImage, setPageImage] = useState("");
    const [visionImage, setVisionImage] = useState("");
    const settings = { dots: true, infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true };

    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [30, 0]
          });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            el: "lines", opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [15, 0]
        });          
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            effect: "slide", color: "#262b35", delay: 50, easing: "easeOutQuad", direction : "tb"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            opacity: [0,1], duration: 600, delay: 100, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-4]', // Select elements with the data attribute
            translateY: [15, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-5]', // Select elements with the data attribute
            e1: "childs", rotateZ: [5, 0], translateY: [30, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-6]', // Select elements with the data attribute
            translateX: [0,0], opacity: [0,1], duration: 1200, delay: 50, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-7]', // Select elements with the data attribute
            effect: "slide", color: "#262b35", delay: 50, easing: "easeOutQuad", direction : "lr"
        });
        anime({
            targets: '[data-anime-8]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0,1], duration: 1200, delay: 100, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-9]', // Select elements with the data attribute
            e1: "childs", translateX: [50, 0], opacity: [0,1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-10]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0,1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
    }, [])
   
    useEffect(() => {
        const makeRequest = async () => {
            let jsn = await props.data;
            if (jsn != null) {
                if(jsn.Testonomials != null){
                    let parsedArray = JSON.parse(jsn.Testonomials);
                    setTestData(parsedArray);
                }
                if(jsn.About != null){
                    if(JSON.parse(jsn.About).length){
                        setAboutData(JSON.parse(jsn.About)[0]);
                        setPageImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.PageImage);
                        setVisionImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.VisionImage);
                    }
                }
            }
        }
        makeRequest();
    }, [props.data]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }) .catch(() => { return false; });
      }

    function remove_n_words(words, n) {
        if(words != null){
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }
    
    function getLastNWords(str, n) {
        if(str != null){
            const words = str.split(' ');
            if (words.length < n) {
              return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');    
        }
      }

      return (
        <Fragment>
        <section className="cover-background page-title-big-typography ipad-top-space-margin">
            <div className="container">
                <div className="row align-items-center align-items-lg-end justify-content-center extra-very-small-screen g-0">
                    <div className="col-xxl-5 col-xl-6 col-lg-7 position-relative page-title-extra-small md-mb-30px md-mt-auto" data-anime>
                        <h1 className="text-base-color">About Sky Home Developers</h1>
                        <h2 className="alt-font text-dark-gray fw-500 mb-0 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Welcome to Sky <span className="fw-700 text-highlight d-inline-block">Home<span className="bg-base-color h-10px bottom-3px opacity-3 separator-animation"></span></span> Developers.</h2>
                    </div>
                    <div className="col-lg-5 offset-xxl-2 offset-xl-1 border-start border-2 border-color-base-color ps-40px sm-ps-25px md-mb-auto">
                        <span className="d-block w-85 lg-w-100" data-anime-1>{aboutData.WelcomeShort}
                        </span>
                    </div>
                </div>
            </div>
        </section>
        {/*  end page title */}
        {/*  start section */}
        <section className="overflow-hidden p-0">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0 position-relative">
                        <img src={CheckImage(pageImage) ? pageImage : "assets/images/about.jpg" } alt="" className="w-100" />
                        <div className="alt-font fw-600 fs-350 lg-fs-275 md-fs-250 xs-fs-160 ls-minus-5px xs-ls-minus-2px position-absolute right-minus-50px lg-right-0px bottom-minus-80px md-bottom-minus-60px xs-bottom-minus-40px text-white text-outline text-outline-width-3px" data-bottom-top="transform: translate3d(80px, 0px, 0px);" data-top-bottom="transform: translate3d(-280px, 0px, 0px);">about</div>
                    </div>
                </div>
            </div>
        </section>
        {/*  end section */}
        {/*  start section */}
        <section className="pb-0"> 
            <div className="container">
                <div className="row align-items-center">  
                    <div className="col-lg-6 order-2 order-lg-1 position-relative align-self-end" data-anime-2> 
                        <figure className="position-relative m-0">
                            <img src={CheckImage(visionImage) ? visionImage : "assets/images/vision_image.jpg" } className="w-100" alt="" />
                            <figcaption className="w-180px bg-white p-30px pt-20px border-radius-8px position-absolute top-150px right-minus-30px lg-right-0px overflow-hidden box-shadow-medium animation-float"> 
                                <span className="alt-font fs-100 fw-700 text-white d-block position-relative z-index-1">16</span>
                                <div className="alt-font fw-500 fs-20 d-block text-dark-gray lh-26 ls-minus-05px">Years of <span className="fw-700">experience.</span></div>
                                <div className="h-150px w-150px border-radius-100 bg-base-color position-absolute left-minus-10px top-minus-50px z-index-0"></div>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-xl-5 offset-xl-1 col-lg-6 mb-8 order-1 order-lg-2 md-mb-40px" data-anime-3> 
                        <span className="fs-20 d-inline-block mb-15px text-base-color">VISION & MISSION</span> 
                        <h2 className="alt-font fw-500 text-dark-gray ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">{remove_n_words(aboutData.VMHead, 2)} <span className="fw-700 text-highlight d-inline-block">{getLastNWords(aboutData.VMHead, 2)}<span className="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span></h2> 
                        <span className="fs-20 d-inline-block mb-15px text-base-color">VISION</span> 
                        <p className="w-80 lg-w-100">{aboutData.Vision}</p>
                        <span className="fs-20 d-inline-block mb-15px text-base-color">MISSION</span> 
                        <p className="w-80 lg-w-100">{aboutData.Mission}</p>

                        <a href="#" className="btn btn-large btn-link btn-hover-animation-switch text-dark-gray p-0">
                            <span>
                                <span className="btn-text">Find property</span>
                                <span className="btn-icon"><i className="feather icon-feather-arrow-right"></i></span>
                            </span> 
                        </a>
                    </div>
                </div>
            </div>
        </section>
        {/*  end section */}
        {/*  start section */}
        <section className="bg-gradient-very-light-gray">
            <div className="container">
                <div className="row mb-3">
                    <div className="col-12 text-center" data-anime-4>
                        <h3 className="alt-font fw-500 text-dark-gray ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Giving quality with <span className="fw-700 text-highlight d-inline-block">property<span className="bg-base-color h-10px bottom-5px opacity-3 separator-animation"></span></span></h3>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center mb-5 xs-mb-8" data-anime-5>
                    {/*  start features box item */}
                    {
                        aboutData.Card1Head ? 
                        <div className="col icon-with-text-style-07 transition-inner-all md-mb-30px">
                            <div className="bg-white feature-box box-shadow-quadruple-large box-shadow-quadruple-large-hover text-center p-16 lg-p-14 border-radius-10px last-paragraph-no-margin">
                                <div className="feature-box-icon mb-20px"> 
                                    <img src={CheckImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card1Image) ? process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card1Image : "assets/images/hand-shake.png"} className="h-80px" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-600 text-dark-gray fs-20 d-inline-block mb-5px">{aboutData.Card1Head}</span>
                                    <p>{aboutData.Card1Details}</p>
                                </div>                        
                            </div>
                        </div>                        
                        : ""
                    }
                    {
                        aboutData.Card2Head ? 
                        <div className="col icon-with-text-style-07 transition-inner-all md-mb-30px">
                            <div className="bg-white feature-box box-shadow-quadruple-large box-shadow-quadruple-large-hover text-center p-16 lg-p-14 border-radius-10px last-paragraph-no-margin">
                                <div className="feature-box-icon mb-20px"> 
                                    <img src={CheckImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card2Image) ? process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card2Image : "assets/images/user-friendly.png"} className="h-80px" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-600 text-dark-gray fs-20 d-inline-block mb-5px">{aboutData.Card2Head}</span>
                                    <p>{aboutData.Card2Details}</p>
                                </div>                        
                            </div>
                        </div>                        
                        : ""
                    }
                    {
                        aboutData.Card3Head ? 
                        <div className="col icon-with-text-style-07 transition-inner-all md-mb-30px">
                            <div className="bg-white feature-box box-shadow-quadruple-large box-shadow-quadruple-large-hover text-center p-16 lg-p-14 border-radius-10px last-paragraph-no-margin">
                                <div className="feature-box-icon mb-20px"> 
                                    <img src={CheckImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card3Image) ? process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card3Image : "assets/images/verified.png"} className="h-80px" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-600 text-dark-gray fs-20 d-inline-block mb-5px">{aboutData.Card3Head}</span>
                                    <p>{aboutData.Card3Details}</p>
                                </div>
                            </div>
                        </div>                        
                        : ""
                    }
                    {
                        aboutData.Card4Head ? 
                        <div className="col icon-with-text-style-07 transition-inner-all md-mb-30px">
                            <div className="bg-white feature-box box-shadow-quadruple-large box-shadow-quadruple-large-hover text-center p-16 lg-p-14 border-radius-10px last-paragraph-no-margin">
                                <div className="feature-box-icon mb-20px"> 
                                    <img src={CheckImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card4Image) ? process.env.REACT_APP_API_HOST +"/About/"+aboutData.Card4Image : "assets/images/list.png"} className="h-80px" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="alt-font fw-600 text-dark-gray fs-20 d-inline-block mb-5px">{aboutData.Card4Head}</span>
                                    <p>{aboutData.Card4Details}</p>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <div className="row justify-content-center" data-anime-6>
                    <div className="col-auto icon-with-text-style-08">
                        <div className="feature-box feature-box-left-icon-middle">
                            <div className="feature-box-icon me-10px">
                                <i className="bi bi-patch-check icon-medium text-base-color"></i>
                            </div>
                            <div className="feature-box-content alt-font text-dark-gray fs-22 fw-600">
                                {aboutData.Line1} <span className="text-decoration-line-bottom">{aboutData.Underline1}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*  end section */}
        {/*  start section */}
        <section className="position-relative overflow-hidden pt-0">
            <img src="images/demo-real-estate-07.png" alt="" className="position-absolute left-100px top-50px z-index-minus-1" data-bottom-top="transform: translate3d(80px, 0px, 0px);" data-top-bottom="transform: translate3d(-180px, 0px, 0px);" />
            <img src="images/demo-real-estate-08.png" alt="" className="position-absolute right-100px top-100px z-index-minus-1" data-bottom-top="transform:scale(1.4, 1.4) translate3d(0px, 0px, 0px);" data-top-bottom="transform:scale(1, 1) translate3d(-400px, 0px, 0px);" />
            <div className="container">  
                <div className="row align-items-center justify-content-center">  
                    <div className="col-lg-4 text-end md-mb-50px" data-anime-7> 
                        <figure className="position-relative m-0">
                            <img src="assets/images/logo/logo.png" className="w-90 border-radius-6px" alt="" />
                            <figcaption className="position-absolute bg-dark-gray border-radius-10px box-shadow-quadruple-large bottom-100px xs-bottom-minus-20px left-minus-30px md-left-0px w-230px xs-w-210px text-center last-paragraph-no-margin animation-float">
                                <div className="bg-white pt-35px pb-35px border-radius-8px mb-15px position-relative top-minus-1px">
                                    <h1 className="fw-700 ls-minus-3px text-dark-gray mb-0 alt-font">4.8</h1>
                                    <div className="text-golden-yellow fs-18 ls-1px">
                                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <span className="text-dark-gray d-block alt-font fw-600">1488 Reviews</span>
                                    <div className="d-inline-block fs-12 text-uppercase bg-base-color ps-20px pe-20px lh-30 text-white border-radius-100px box-shadow-large">Excellent score</div>
                                </div>
                                <img src="images/demo-real-estate-trustpilot.svg" className="h-30px mb-20px" alt="" />
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-12 text-center text-lg-start" data-anime-8>
                        <span className="fs-20 d-inline-block mb-15px text-base-color">Clients feedback</span>
                        <h2 className="alt-font fw-500 text-dark-gray ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">Here is what our <span className="fw-700 text-highlight d-inline-block">client's<span className="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span> have to say</h2>
                        <div className="swiper position-relative" data-slider-options='{ "autoHeight": true, "loop": true, "allowTouchMove": true, "autoplay": { "delay": 4000, "disableOnInteraction": false }, "navigation": { "nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev" }, "effect": "fade" }'>
                            <Slider className="swiper-wrapper mb-40px" {...settings}>
                                {/*  start text slider item */}
                                {testoData.length > 0 && testoData.map((record, index) => (
                                    
                                    <div key={index} className="swiper-slide review-style-08">
                                        <p className="w-80 lg-w-100">{record.Details}</p>
                                        <div className="mt-20px">
                                        {record.Image ? <img className="rounded-circle w-95px h-95px me-15px" src={CheckImage(process.env.REACT_APP_API_HOST +"/Testinomials/"+ aboutData.Image) ? process.env.REACT_APP_API_HOST +"/Testinomials/"+ aboutData.Image : ""} alt="" /> : ""}
                                            <div className="d-inline-block align-middle text-start">
                                                <div className="text-dark-gray alt-font fs-20"><span className="fw-700">{record.Name}</span> </div>
                                                <div className="review-star-icon fs-18">
                                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>
                                ))}
                                {/*  end text slider item */}
                                </Slider>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                {/*  start slider navigation */}
                                <div className="slider-one-slide-prev-1 swiper-button-prev slider-navigation-style-04 border border-color-extra-medium-gray"><i className="fa-solid fa-arrow-left icon-small text-dark-gray"></i></div>
                                <div className="slider-one-slide-next-1 swiper-button-next slider-navigation-style-04 border border-color-extra-medium-gray"><i className="fa-solid fa-arrow-right icon-small text-dark-gray"></i></div>
                                {/*  end slider navigation */} 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
        {/*  end section */}    
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallAbout
}

export default connect(mapStateToProps, mapActionToProps)(About);