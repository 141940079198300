import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import Swal from "sweetalert2";

const Contact = (props) => {
    const [pageData, setPageData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [pageImage, setPageImage] = useState("");
    const [formValue, setFormValue] = useState({ Name: '', Email: '', PhoneNo: '', Message: '', Token: '', PropertyId: 0, Answer: '' });
    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [Answer, setAnswer] = useState(0);

    const [errorName, seterrorName] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorPhoneNo, seterrorPhoneNo] = useState("");
    const [errorMessage, seterrorMessage] = useState("");
    const [errorAnswer, seterrorAnswer] = useState("");

    function validateName() {
        let formIsValid = false;
        if (!formValue["Name"]) {
            formIsValid = false;
            seterrorName("*Enter Name.");
        } else if (typeof formValue["Name"] !== "undefined") {
            if (!formValue["Name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
                formIsValid = false;
                seterrorName("*Please enter Alphabet characters only.");
            } else {
                formIsValid = true;
                seterrorName("");
            }
        }
        return formIsValid;
    }

    function validateEmail() {
        let formIsValid = false;
        if (!formValue["Email"]) {
            formIsValid = false;
            seterrorEmail("*Enter your email.");
        } else if (typeof formValue["Email"] === "undefined") {
            formIsValid = false;
            seterrorEmail("*Enter your email.");
        } else {
            formIsValid = true;
            seterrorEmail("");
        }
        return formIsValid;
    }

    function validatephone_no() {
        let formIsValid = false;
        if (!formValue["PhoneNo"]) {
            formIsValid = false;
            seterrorPhoneNo("*Enter your phone no.");
        } else if (typeof formValue["PhoneNo"] === "undefined") {
            formIsValid = false;
            seterrorPhoneNo("*Enter your phone no.");
        } else {
            formIsValid = true;
            seterrorPhoneNo("");
        }
        return formIsValid;
    }

    function validateMessage() {
        let formIsValid = false;
        if (!formValue["Message"]) {
            formIsValid = false;
            seterrorMessage("*Enter your message.");
        } else if (typeof formValue["Message"] === "undefined") {
            formIsValid = false;
            seterrorMessage("*Enter your message.");
        } else {
            formIsValid = true;
            seterrorMessage("");
        }
        return formIsValid;
    }

    function validateAnswer() {
        let formIsValid = false;
        if (!formValue["Answer"]) {
            formIsValid = false;
            seterrorAnswer("*Enter your answer.");
        } else if (typeof formValue["Answer"] === "undefined") {
            formIsValid = false;
            seterrorAnswer("*Enter your answer.");
        } else {
            formIsValid = true;
            seterrorAnswer("");
        }
        return formIsValid;
    }

    function validateAddForm() {
        let name = validateName(); let email = validateEmail();
        let phone = validatephone_no(); let message = validateMessage(); let answer = validateAnswer();
        let valid = name && email && phone && message && answer;
        return valid;
    }

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            el: "lines", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad", translateY: [15, 0]
        });
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            e1: "childs", opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            e1: "childs", translateX: [50, 0], opacity: [0, 1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
    }, [])

    useEffect(() => {
        const makeRequest = async () => {
            setNum1(randomNumberInRange(1, 10));
            setNum2(randomNumberInRange(1, 10));

            let jsn = await props.data;
            if (jsn != null) {
                if (jsn.Contact != null) {
                    if (JSON.parse(jsn.Contact).length) {
                        setPageData(JSON.parse(jsn.Contact)[0]);
                        // setPageImage(process.env.REACT_APP_API_HOST + "/Contact/" + pageData.PageImage);
                        setPageImage("https://iamondot.com/Pages/" + pageData.PageImage);
                    }
                    if (JSON.parse(jsn.Company).length) {
                        setCompanyData(JSON.parse(jsn.Company)[0]);
                    }
                }
            }
        }
        makeRequest();
    }, [props.data]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    function remove_n_words(words, n) {
        if (words != null) {
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }

    function getLastNWords(str, n) {
        if (str != null) {
            const words = str.split(' ');
            if (words.length < n) {
                return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (validateAddForm()) {
            var answer = num1 + num2;
            if (answer == formValue.Answer) {
                const allInputValue = { Name: formValue.Name, Email: formValue.Email, PhoneNo: formValue.PhoneNo, Message: formValue.Message, Token: process.env.REACT_APP_API_TOKEN, PropertyId: 0 };
                console.log(allInputValue);
                var authOptions = {
                    method: 'post',
                    url: process.env.REACT_APP_API_HOST + "SaveLead",
                    data: allInputValue,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    json: true
                };
                axios(authOptions)
                    .then((response) => {
                        Swal.fire(
                            "Good job!",
                            "Enquiry Submitted Successfully.",
                            "success"
                        );
                    })
                    .catch((error) => {
                        alert(error)
                    })
            }
            else {
                Swal.fire("Error!", "Invalid Answer", "error");
            }
        }
        else {
            Swal.fire("Error!", "Enter Required Fields", "error");
        }
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    }
    return (
        <Fragment>
            <section class="cover-background page-title-big-typography ipad-top-space-margin">
                <div class="container">
                    <div class="row align-items-center align-items-lg-end justify-content-center extra-very-small-screen g-0">
                        <div class="col-xxl-5 col-xl-6 col-lg-7 position-relative page-title-extra-small md-mb-30px md-mt-auto" data-anime>
                            <h1 class="text-base-color">Build relationships</h1>
                            <h2 class="alt-font text-dark-gray fw-500 mb-0 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="700">{remove_n_words(pageData.WelcomeHead, 3)} <span class="fw-700 text-highlight d-inline-block">{getLastNWords(pageData.WelcomeHead, 3)}<span class="bg-base-color h-10px bottom-10px opacity-3 separator-animation"></span></span></h2>
                        </div>
                        <div class="col-lg-5 offset-xxl-2 offset-xl-1 border-start border-2 border-color-base-color ps-40px sm-ps-25px md-mb-auto">
                            <span class="d-block w-85 lg-w-100" data-anime-1>{pageData.WelcomeDetails}</span>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end page title */}
            {/*  start section */}
            <section class="overflow-hidden p-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 p-0 position-relative">
                            <img src={pageImage ? pageImage : "assets/images/demo-real-estate-contact-01.jpg"} alt="" className="w-100" />
                            <div class="alt-font fw-600 fs-350 lg-fs-275 md-fs-250 sm-fs-200 xs-fs-140 ls-minus-5px xs-ls-minus-2px position-absolute right-minus-50px lg-right-minus-0px bottom-minus-80px md-bottom-minus-60px xs-bottom-minus-40px text-white text-outline text-outline-width-3px" data-bottom-top="transform: translate3d(80px, 0px, 0px);" data-top-bottom="transform: translate3d(-280px, 0px, 0px);">contact</div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section class="pt-0">
                <div class="container">
                    <div class="row justify-content-center align-items-center" data-anime-2>
                        <div class="col-lg-6 pt-8 pb-8 text-center text-sm-start">
                            <h6 class="alt-font fw-700 text-dark-gray mb-15px">Navi Mumbai</h6>
                            <div class="row row-cols-1 row-cols-sm-2 mb-10">
                                <div class="col last-paragraph-no-margin xs-mb-20px">
                                    <span class="fs-18 alt-font fw-600 d-block text-dark-gray mb-5px">Sky Home Developers</span>
                                    <p class="w-80 lg-w-100">{companyData.Address}</p>
                                </div>
                                <div class="col">
                                    <span class="fs-18 alt-font fw-600 d-block text-dark-gray mb-5px">Get in touch</span>
                                    {
                                        companyData.PhoneNo ? companyData.PhoneNo.split(',').map((item, index) => (
                                            item ? <div> <Link key={index} to={"tel:" + item.trim()}>{item}</Link> <br></br></div> : ""
                                        )) : ""
                                    }
                                    {
                                        companyData.Email ? companyData.Email.split(',').map((item, index) => (
                                            item ? <div> <Link key={index} className="fs-16 text-dark-gray" to={"mailto:" + companyData.Email}>{companyData.Email}</Link> <br></br> </div> : ""
                                        )) : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 align-self-start contact-form-style-03 position-relative">
                            <div class="bg-white box-shadow-double-large p-12 lg-p-9 border-radius-10px">
                                <h3 class="fw-700 alt-font text-dark-gray mb-30px sm-mb-20px">How can help you?</h3>
                                <form onSubmit={handleSubmit}>
                                    <div class="position-relative form-group mb-20px">
                                        <span class="form-icon text-medium-gray opacity-6"><i class="bi bi-emoji-smile"></i></span>
                                        <input class="ps-0 border-radius-0px bg-transparent border-color-extra-medium-gray form-control required" type="text" name="Name" placeholder="Your name*" onChange={handleInput} onBlur={validateName} />
                                        {errorName && <p>{errorName}</p>}
                                    </div>
                                    <div class="position-relative form-group mb-20px">
                                        <span class="form-icon medium-gray opacity-6"><i class="bi bi-envelope"></i></span>
                                        <input class="ps-0 border-radius-0px bg-transparent border-color-extra-medium-gray form-control required" type="email" name="Email" placeholder="Your email address*" onChange={handleInput} onBlur={validateEmail} />
                                        {errorEmail && <p>{errorEmail}</p>}
                                    </div>
                                    <div class="position-relative form-group mb-20px">
                                        <span class="form-icon text-medium-gray opacity-6"><i class="bi bi-telephone"></i></span>
                                        <input class="ps-0 border-radius-0px bg-transparent border-color-extra-medium-gray form-control required" type="tel" name="PhoneNo" placeholder="Your phone" onChange={handleInput} onBlur={validatephone_no} />
                                        {errorPhoneNo && <p>{errorPhoneNo}</p>}
                                    </div>
                                    <div class="position-relative form-group form-textarea mt-15px mb-0">
                                        <textarea class="ps-0 border-radius-0px bg-transparent border-color-extra-medium-gray form-control" name="Message" placeholder="Your message" rows="3" onChange={handleInput} onBlur={validateMessage}></textarea>
                                        <span class="form-icon medium-gray opacity-6"><i class="bi bi-chat-square-dots"></i></span>
                                        {errorMessage && <p>{errorMessage}</p>}
                                    </div>
                                    <div class="position-relative form-group mb-20px">
                                        <label>{num1}</label> + <label>{num2}</label> = <label>
                                            <input class="form-control required" type="text" name="Answer" placeholder="Answer" onChange={handleInput} onBlur={validateAnswer} /></label>
                                        {errorAnswer && <p>{errorAnswer}</p>}
                                    </div>

                                    <button class="btn btn-medium btn-base-color btn-round-edge mt-35px fw-600" type="submit">Send message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section class="bg-very-light-gray p-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 p-0">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6453.88322449724!2d73.0333522!3d19.0138751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c335f985bf87%3A0xc5e2b66feb321eb8!2sSKY%20HOMES%20(SKY%20Group%20of%20Companies)!5e1!3m2!1sen!2sin!4v1687838528595!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                width="100%" height="460" frameborder="0" style={{ border: 0 }}></iframe>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end section */}
            {/*  start section */}
            <section class="overlap-height half-section">
                <div class="container overlap-gap-section">
                    <div class="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center" data-anime-3>
                        {/*  start features box item */}
                        <div class="col icon-with-text-style-01 md-mb-40px">
                            <div class="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                <div class="feature-box-icon me-25px">
                                    <img src="assets/images/demo-real-estate-contact-02.jpg" class="h-85px" alt="" />
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block fs-19 lh-26 alt-font text-dark-gray fw-600 w-75 lg-w-100">Take pride in your work.</span>
                                </div>
                            </div>
                        </div>
                        {/*  end features box item */}
                        {/*  start features box item */}
                        <div class="col icon-with-text-style-01 md-mb-40px">
                            <div class="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                <div class="feature-box-icon me-25px">
                                    <img src="assets/images/demo-real-estate-contact-03.jpg" class="h-85px" alt="" />
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block fs-19 lh-26 alt-font text-dark-gray fw-600 w-75 lg-w-100">Deliver on your promises.</span>
                                </div>
                            </div>
                        </div>
                        {/*  end features box item */}
                        {/*  start features box item */}
                        <div class="col icon-with-text-style-01 xs-mb-40px">
                            <div class="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                <div class="feature-box-icon me-25px">
                                    <img src="assets/images/demo-real-estate-contact-04.jpg" class="h-85px" alt="" />
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block fs-19 lh-26 alt-font text-dark-gray fw-600 w-75 lg-w-100">Work with the supreme.</span>
                                </div>
                            </div>
                        </div>
                        {/*  end features box item */}
                        {/*  start features box item */}
                        <div class="col icon-with-text-style-01">
                            <div class="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                <div class="feature-box-icon me-25px">
                                    <img src="assets/images/demo-real-estate-contact-05.jpg" class="h-85px" alt="" />
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block fs-15 lh-26 alt-font text-dark-gray fw-600 w-75 lg-w-100">Transparency in dealings.</span>
                                </div>
                            </div>
                        </div>
                        {/*  end features box item */}
                    </div>
                </div>
            </section>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallContact
}

export default connect(mapStateToProps, mapActionToProps)(Contact);